<template>
  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <n-message-provider :placement="messagePlacement">
      <ToastInjector />
      <div class="d-flex sr__iframe-layout flex-column justify-content-center">
        <img src="/images/Logo-AgentSnap.png" alt="" />
        <slot />
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script lang="ts" setup>
import { darkTheme } from 'naive-ui';
import { getGlobalThemeOverrides } from '../../base/utils/theme-overrides';
import ToastInjector from '../../base/components/ToastInjector.vue';

const themeOverrides = getGlobalThemeOverrides();
</script>

<style scoped lang="scss">
.sr__iframe-layout {
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 240px;
    margin: 15px auto 15px;
  }
}
</style>
